// printing the name
console.log(
  `       _               _   _       \n      | |             | | (_)      \n _   _| |_ _ __   __ _| |  _  ___  \n| | | | __| '_ \ / _\` | | | |/ _ \ \n| |_| | |_| |_) | (_| | |_| | (_) |\n \__,_|\__| .__/ \__,_|_(_)_|\___/ \n          | |                      \n          |_|                      \n`
);
console.log("I think, nothing to hack here :D.");

const fields = ["name", "message", "email"];

async function sendMessage(name: string, email: string, message: string) {
  // const url = "http://localhost:8787/api/contact-me";
  const url = "https://worker.utpal.io/api/contact-me";

  return fetch(url, {
    body: JSON.stringify({ message, name, email }),
    method: "POST",
    headers: { "Content-Type": "application/json" }
  });
}

function flashToggle(
  className: string,
  message?: string,
  flash = false,
  delay = 1000
) {
  document.querySelectorAll(className).forEach(elm => {
    elm.classList.add("show");

    let oldContent: string;

    if (message) {
      oldContent = elm.textContent || "";
      elm.innerHTML = message;
    }

    if (flash) {
      setTimeout(() => {
        elm.classList.remove("show");

        if (message) {
          elm.innerHTML = oldContent;
        }
      }, delay);
    }
  });
}

function setError(name: string, error: string) {
  const elm = document.querySelector(`[name=${name}]`)?.nextElementSibling;

  if (elm && elm.classList.contains("invalid-feedback")) {
    elm.innerHTML = error;
  }
}

function resetAlerts() {
  document.querySelectorAll(".success").forEach(elm => {
    elm.classList.remove("show");
    elm.innerHTML =
      "Message sent! Appreciate your contact. I'll get back to you soon.";
  });
  document.querySelectorAll(".error").forEach(elm => {
    elm.classList.remove("show");
    elm.innerHTML = "Something went wrong!";
  });
}

function disableButtons() {
  document.querySelectorAll("form button").forEach(btn => {
    btn.setAttribute("disabled", "true");
  });
}

function enableButtons() {
  document.querySelectorAll("form button").forEach(btn => {
    btn.removeAttribute("disabled");
  });
}

document
  .getElementById("contact")
  ?.addEventListener("submit", function (e: SubmitEvent) {
    e.preventDefault();
    disableButtons();

    resetAlerts();

    const data = new FormData(this as HTMLFormElement);

    const name = data.get("name") as string;
    const message = data.get("message") as string;
    const email = data.get("email") as string;

    sendMessage(name, email, message)
      .then(async res => {
        const data = await res.json();

        if (res.ok) {
          flashToggle(".success", data.message);
          fields.forEach(field => {
            const elm = document.querySelector(
              `[name=${field}]`
            ) as HTMLInputElement;
            if (elm) {
              elm.value = "";
            }
          });
        } else if (res.status === 422) {
          flashToggle(
            ".error",
            "Invalid data, please provide correct information.",
            false
          );
        } else {
          flashToggle(".error", data.message);
        }

        if (data.fields) {
          Object.keys(data.fields).forEach(key => {
            setError(key, data.fields[key]);
          });
        }
      })
      .finally(() => {
        enableButtons();
      });
  });

document.getElementById("contact")?.addEventListener("reset", function () {
  resetAlerts();
  fields.forEach(key => {
    setError(key, "");
  });
});
